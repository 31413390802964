<template>
	<div class="products-main">
		<div class="page_banner"><img src="@/assets/images/countryside-banner.jpg">
			<div class="words">
				<p>
					<span>深港文化</span>
					服务至上
				</p>
			</div>
		</div>
		<div class="products layout">
			<MenuLayout />
			<div class="products-contact">
				<div class="BreadCrumbs"><span>深港文化</span>
					<p class="crumbs"><a>您的位置：</a> <a>产品</a>> <a>深港文化</a> </p>
				</div>


				<div class="content">
					<h3><b>产品介绍</b></h3>

					
					<img src="@/assets/images/products/culture/culture_01.jpg" alt="">
					<img src="@/assets/images/products/culture/culture_02.jpg" alt="">
					<img src="@/assets/images/products/culture/culture_03.jpg" alt="">
					<img src="@/assets/images/products/culture/culture_04.jpg" alt="">
					<img src="@/assets/images/products/culture/culture_05.jpg" alt="">
					<img src="@/assets/images/products/culture/culture_06.jpg" alt="">
					<img src="@/assets/images/products/culture/culture_07.jpg" alt="">
					<img src="@/assets/images/products/culture/culture_08.jpg" alt="">
					<img src="@/assets/images/products/culture/culture_09.jpg" alt="">
					<img src="@/assets/images/products/culture/culture_10.jpg" alt="">
					<img src="@/assets/images/products/culture/culture_11.jpg" alt="">
					<img src="@/assets/images/products/culture/culture_12.jpg" alt="">
					<img src="@/assets/images/products/culture/culture_13.jpg" alt="">
					<img src="@/assets/images/products/culture/culture_14.jpg" alt="">
					<img src="@/assets/images/products/culture/culture_15.jpg" alt="">
					<img src="@/assets/images/products/culture/culture_16.jpg" alt="">
					<img src="@/assets/images/products/culture/culture_17.jpg" alt="">
					<img src="@/assets/images/products/culture/culture_18.jpg" alt="">
					<img src="@/assets/images/products/culture/culture_19.jpg" alt="">
					<img src="@/assets/images/products/culture/culture_20.jpg" alt="">


				</div>


				<div class="productsChilder">
					<h3 class="productsChilder-title">港深文化交易简介</h3>
					<div class="content">
						<p class="indent">国尊商业是央企重组背景下独立开发的全品<br />
							类集合式商城:商城品类丰富多样,数码电器·手机数码·健康食品·美妆护肤·家具齐全<br />
							家居电器品牌:华为·小米·云米·美的-TCL-海信;<br />
							手机数码:华为·苹果·小米·三星·Oppo:健康食品:海晏堂-燕之屋·大连獐子岛海参山东黄金万足金9999:多款克数选择;大闸蟹精选:六款精选套盒任意选;港深赋能交易品种:<br />
							红山玉器:91.46元;中国船舶:1046元;<br />
							中国共产党成立90周年:369元:红军长征胜利80周年:162元;<br />
							国尊商城购买商品港深集团赋能空投线上藏品<br />
							高品质好品牌产品、硬通货、您真正所需的好品牌好产品好平台!
						</p>
					</div>
				</div>

				<div class="productsChilder">
					<h3 class="productsChilder-title">企业理念·</h3>
					<div class="content">
						<h3><b>企业理念·*服务至上</b></h3>

						<p class="indent">
							*服务至上为他人做事,并使他人从中受益的一种有偿或无偿的活动。Attitude is everything, detaildecides success or failure
						</p>
						<br/>
						<h3><b>*团队荣誉</b></h3>
						<p class="indent">
							为他人做事，并使他人从中受益的一种有偿或无偿的活动.态度是一切，细节决定成败*团队荣誉团队荣誉个人荣誉激励团队发展。Attitude is everything,
							detaildecides success or failure
							团队荣誉个人荣誉激励团队发展.态度是一切，细节决定成败。
						</p>
						<br/>
						<h3><b>*规律法则</b></h3>
						<p class="indent">
							*规律法则运行、运作规律所遵循的法则。Attitude is everything, detaildecides success or failure
							运行、运作规律所遵循的法则.态度是一切，细节决定成败。创造价值价值分享财富起航引领未来
							创造价值价值分享财富起航引领未来※
						</p>

					</div>
				</div>

			</div>
		</div>

	</div>
</template>
<script>
import MenuLayout from "./components/menu"
export default ({
	components: {
		MenuLayout,
	},
	data() {
		return {
			currentComponts: 'OilFumeSeparation'
		}
	},
	methods: {

	}
});
</script>
